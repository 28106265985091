<template>
    <div>
        <el-card class="box-card card-contanier">
            <!-- <div slot="header" class="clearfix card-header">
              <span>事件操作</span>
            </div> -->
            <div class="card-content">
            </div>
            <div class="card-content">
                <el-form :inline="true" @submit.native.prevent>
                    <div style="float:left;width: 90%">
                        <el-form-item label="商户标签名称：">
                            <el-input v-model="searchForm.merLabelName" placeholder="请输入商户标签名称" size="medium"
                                      clearable
                                      @keyup.enter.native="search"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="search" icon="el-icon-search" size="medium">搜索</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="refresh" icon="el-icon-refresh" size="medium">重置</el-button>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button
                                type="primary"
                                @click="dialogVisible = true"
                                size="medium"
                        >新增</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-card>

        <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                size="mini"
                border
                stripe
        >
            <el-table-column type="index"  :index="indexMethod" label="序号" align="center">
            </el-table-column>
            <el-table-column prop="merLabelName" label="商户标签名称" align="center">
            </el-table-column>
            <el-table-column prop="merLabelLevel" label="商户标签" align="center">
            </el-table-column>
            <el-table-column prop="meId" label="商户Id" align="center">
            </el-table-column>
            <el-table-column prop="merNewDatetime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column prop="merUpdateTime" label="更新时间" align="center">
            </el-table-column>
            <el-table-column prop="merLabelNotes" label="备注" align="center">
            </el-table-column>
            <el-table-column prop="icon" label="操作" width="200" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-popover
                            placement="right"
                            width="650"
                            trigger="click"
                    >
                        <el-table :data="gridDataR" >
                            <el-table-column type="index" label="序号" align="center">
                            </el-table-column>
                            <el-table-column width="150" property="vacarId" label="会员卡号"></el-table-column>
                            <el-table-column width="150" property="vcardName" label="会员卡名称"></el-table-column>
                            <el-table-column width="150" property="vcardMeName" label="所属商户名称"></el-table-column>
                            <el-table-column width="150" property="vcardVil" label="有效期"></el-table-column>
                        </el-table>
                        <el-button slot="reference" @click="editEcut(scope.row.merLabelId)"  type="text" size="small">展开</el-button>
                    </el-popover>
                    <el-divider direction="vertical"></el-divider>
                    <el-button @click="editHandler(scope.row.merLabelId)" type="text" size="small">编辑</el-button>
                    <el-divider direction="vertical"></el-divider>
                    <template>
                        <el-popconfirm
                                title="确认删除当前选中数据？"
                                @confirm="delHandle(scope.row.merLabelId)"
                        >
                            <el-button type="text" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>

        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="current"
                :page-size="size"
                :total="total"
        ></el-pagination>

        <!--新增对话框  -->
        <el-dialog :visible.sync="dialogVisible" width="35%" :before-close="handleClose"
                   :close-on-click-modal="false">
            <el-button style="font-size: 16px" @click="manage()">标签管理</el-button>
            <el-form
                    :model="editForm"
                    :rules="editFormRules"
                    ref="editForm"
                    v-show="show"
                    label-width="100px"
                    class="demo-editForm"
            >
                <el-form-item label="标签Id:" prop="aliLabelId" label-width="120px" style="width: 63%">
                    <el-input v-model="editForm.aliLabelId" auto-complete="off">
                    </el-input>
                </el-form-item>
                <el-form-item label="标签名称:" prop="merLabelName" label-width="120px" style="width: 63%">
                    <el-input v-model="editForm.merLabelName" auto-complete="off">
                    </el-input>
                </el-form-item>
                <el-form-item label="商盟标签:" prop="aliLabelLevel" label-width="120px" style="width: 63%">
                    <el-input v-model="editForm.aliLabelLevel" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注:" prop="aliLabelNotes" label-width="120px" style="width: 63%">
                    <el-input v-model="editForm.aliLabelNotes" auto-complete="off">
                    </el-input>
                </el-form-item>


            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('editForm')">保 存</el-button>
                <el-button @click="resetForm('editForm')">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import api from "../../api/user";

    export default {
        name: "AssetType",
        data() {
            return {
                dialogVisible: false,
                nohigh: true,
                fileList: "",
                editForm: {},
                gridData :[],
                gridDataR:[],
                searchForm:{},
                imageUrl: '',
                total: 0,
                dialogVisibl: false,
                size: 10,
                current: 1,
                Level1: '2',
                show:true,
                isShow:false,
                dictsAssetProvinceclass: [],
                dictsAssetCityclass: [],
                dictsAssetquclass: [],
                Level2: '1',
                editFormRules: {
                    // meLogo: [{required: true, message: "请插入logo", trigger: "blur"}],
                    // meName: [{required: true, message: "请输入商户名称", trigger: "blur"}],
                    // meAbb: [{required: true, message: "请输入商户简称", trigger: "blur"}],
                    // meContact: [{required: true, message: "请输入联系人", trigger: "blur"}],
                    // mePhone: [{required: true, message: "请输入联系人电话", trigger: "blur"}],
                },
                tableData: [],
                url: {
                    list: "/sys/time/list",
                    selectById: "/sys/time/selectById",
                    deleteById: "/sys/time/deleteById"
                },
            };
        },
        created() {
            this.getTimeLimit();
            // this.getMenuTree();
        },
        methods: {
            refresh() {
                this.searchForm = {};
                this.editForm = {};
                this.getTimeLimit();
            },

            //列表数据查询
            getTimeLimit() {
                api.selectMrcLabeList({
                    params:{
                        merLabelName:this.searchForm.merLabelName,
                        current: this.current,
                        size: this.size
                    }
                })
                    .then(res => {
                        console.log(res)
                        this.tableData = res.data.data.result.records;
                        this.size = res.data.data.result.size;
                        this.current = res.data.data.result.current;
                        this.total = res.data.data.result.total;
                    })
            },
            handleSizeChange(val) {
                this.size = val;
                this.getTimeLimit();
            },
            handleCurrentChange(val) {
                this.current = val;
                this.getTimeLimit();
            },
            indexMethod(index){
                return index +((this.current-1)*10)+1
            },
            search() {
                this.current = 1;
                this.getTimeLimit();
            },
            fileChange(file, fileList){
                this.fileList = file.raw;
                console.log(file.raw)
            },

            //新增和修改的保存
            submitForm(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        let status = this.editForm.merLabelId ? "update" : "save";
                        api.saveMer(status,this.editForm)
                            .then(res => {
                                this.$notify({
                                    title: "prefect",
                                    message: "操作成功",
                                    position: "bottom-right",
                                    type: "success",
                                    onClose: () => {
                                        this.getTimeLimit();
                                    }
                                })
                                this.dialogVisible = false;
                                this.refresh();
                            });
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            getMenuTree() {
                api.regionList().then(res => {
                    this.dictsAssetProvinceclass = res.data.data.result;
                    console.log(this.tableData)
                });
            },
            editEcut(merLabelId) {
                api.selectMerHx({
                    merLabelId
                }).then(res=>{
                    this.gridData=res.data.data.result;
                })
            },
            //根据省份获取市区信息regionId
            getcityclass(regionId) {
                let url=this.editForm.id? "update" : "save";
                console.log(url);
                this.$set(this.editForm, 'city', '')
                this.$set(this.editForm, 'county', '')
                this.$set(this.searchForm, 'city', '')
                this.$set(this.searchForm, 'county', '')

                api.getcityclass(regionId)
                    .then(res => {
                        this.dictsAssetCityclass = res.data.data.result;
                        console.log(this.dictsAssetCityclass)
                    })
            },
            getquclass(regionId) {
                let url=this.editForm.id? "update" : "save";
                console.log(url);

                this.$set(this.editForm, 'county', '')
                this.$set(this.searchForm, 'county', '')

                api.getcityclass(regionId)
                    .then(res => {
                        this.dictsAssetquclass = res.data.data.result;
                        console.log(this.dictsAssetquclass)
                    })
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            handleAvatarSuccess(res, file, fileList) {
                // if(res.success){
                //     if (file.response) {
                //         this.filearrYs.push(file.response.message)
                //         var path="";
                //         if(this.filearrYs.length>0){
                //             path = this.filearrYs.join(",")
                //         }
                //         console.log(this.filearrYs)
                //         var fileSize = file.size / 1024 /1024;
                //         this.saveUploadfileYs(file.name,fileSize)
                //     }
                // }

            },

            //修改时的回显
            editHandler(merLabelId) {
                api.selectMrcLabeHx({
                    params:{
                        merLabelId:merLabelId,
                    }
                })
                    .then(res => {
                        this.editForm = res.data.data.result[0];
                        this.dialogVisible = true;
                    })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
                this.editForm = {};

            },
            handleClose() {
                this.resetForm("editForm");
                this.dialogVisible = false;
                this.editForm = {};
            },

            //根据id删除
            delHandle(merLabelId) {
                api.deleteMer(merLabelId).then(res => {
                    this.getTimeLimit();
                    this.$notify({
                        title: "prefect",
                        message: "操作成功",
                        type: "success",
                        position: "bottom-right"
                    });
                })
            },
            manage(){
                this.show=true;
                this.isShow=false;
            },
            manage2(){
                this.isShow=true;
                this.show=false;
            }
        }
    };
</script>

<style scoped>
    .card-contanier {
        margin-bottom: 5px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .map {
        width: 100%;
        height: 400px;
    }
    .el-pagination {
        float: right;
        margin-top: 22px;
    }
</style>
